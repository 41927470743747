import $ from 'jquery';
import { Calendar } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import translate from 'i18n';
import { clearErrorAndWarn } from 'utils';
import { viewConfigs } from 'calendar_view_helper';
import { getProjectResources, laneClass } from 'resource_helpers';
import { calendarDate } from 'date_utils';
import {
  getProjectEvents,
  hoverText,
  onEventClicked,
  classNames,
  projectStripeColor
} from 'event_helpers';

// Global variables to hold the previous resource id, start, and end on drag+drop
// Workaround should no longer be needed in fullcalendar v4
// See https://github.com/fullcalendar/fullcalendar-scheduler/issues/39
var prevEventTaskId = 0;
var prevStart;
var prevEnd;

const ProjectCalendar = () => {
  $(document).on('turbolinks:load', event => {
    if ($('.project-calendar').length === 0) {
      return;
    }
    clearErrorAndWarn();

    var defaultView = 'listWeek';
    var viewType = defaultView;
    var viewOptions = '';
    if ($(window).width() > 1470) {
      defaultView = 'resourceTimelineThirtyDay';
      viewOptions =
        'resourceTimelineTenDay resourceTimelineTwentyDay resourceTimelineThirtyDay resourceTimelineNinetyDay listWeek';
    } else if ($(window).width() >= 1020) {
      defaultView = 'resourceTimelineTwentyDay';
      viewOptions =
        'resourceTimelineTenDay resourceTimelineTwentyDay resourceTimelineThirtyDay listWeek';
    } else if ($(window).width() >= 768) {
      defaultView = 'resourceTimelineTenDay';
      viewOptions = 'resourceTimelineTenDay resourceTimelineTwentyDay listWeek';
    }

    var calendarHolder = $('.project-calendar')[0];

    var pageURL = window.location.search;
    var startDate = calendarDate(pageURL);

    // Convert string to boolean
    var weekends = $('#weekends').val() === 'true';

    var projectCalendar = new Calendar(calendarHolder, {
      schedulerLicenseKey: '0345976193-fcs-1701481234',
      plugins: [resourceTimelinePlugin, listPlugin],
      initialView: defaultView,
      initialDate: startDate,
      editable: false,
      eventStartEditable: false,
      eventDurationEditable: false,
      eventResourceEditable: false,
      droppable: false,
      weekends: weekends,
      contentHeight: 'auto',
      resourceAreaHeaderContent: translate('booking.resource_title'),
      resourceAreaWidth: '160px',
      resourceOrder: 'sort',
      eventOverlap: true,
      headerToolbar: {
        left: 'today prev,next',
        center: 'title',
        right: viewOptions
      },
      views: viewConfigs(weekends),
      resources: function(fetchInfo, successCallback, failureCallback) {
        getProjectResources(fetchInfo, successCallback);
      },
      events: function(fetchInfo, successCallback, failureCallback) {
        var withStripes = viewType !== 'listWeek';
        getProjectEvents(fetchInfo, successCallback, withStripes);
      },
      eventClassNames: function(arg) {
        return classNames(arg.event);
      },
      eventDidMount: function(arg) {
        arg.el.id = arg.event.id.split('_')[1];
        hoverText(arg.el, arg.event.title);

        if (arg.event.extendedProps.category == 'project') {
          projectStripeColor(arg);
        }
      },
      resourceLabelDidMount: function(arg) {
        hoverText(arg.el, arg.resource.title);
      },
      resourceLabelClassNames: function(arg) {
        return laneClass(arg.resource, true);
      },
      resourceLaneClassNames: function(arg) {
        return laneClass(arg.resource, false);
      },
      eventClick: function(arg) {
        onEventClicked(arg.event, new Date(arg.event.start), false);
      },
      datesSet: function(dateInfo) {
        viewType = dateInfo.view.type;
      }
    });

    projectCalendar.render();
  });
};

export default ProjectCalendar;
