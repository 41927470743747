import $ from 'jquery';
import 'jquery-ui/ui/widgets/dialog';
import { Calendar } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { updateForCategory } from './booking_category_utils';
import createBookingModal from 'booking_modal';
import eventTitle from 'event_title';
import {
  viewConfigs,
  onDateClicked,
  populateEventDefaults
} from 'calendar_view_helper';
import {
  getResources,
  laneClass,
  labelContent,
  workingAndVacation
} from 'resource_helpers';
import updateEvent from 'update_event';
import translate from 'i18n';
import { calendarDate } from 'date_utils';
import {
  getEvents,
  hoverText,
  onEventClicked,
  onEventDrop,
  classNames,
  projectStripeColor
} from 'event_helpers';

// Global variables to hold the previous resource id, start, and end on drag+drop
// Workaround should no longer be needed in fullcalendar v4
// See https://github.com/fullcalendar/fullcalendar-scheduler/issues/39
var prevEventUserId = 0;
var prevStart;
var prevEnd;

const ResourceCalendar = () => {
  $(document).on('click', '.new-booking', event => {
    event.preventDefault();

    const SUNDAY = 0;
    const SATURDAY = 6;
    var tomorrow = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);

    if (tomorrow.getDay() == SATURDAY) {
      tomorrow.setDate(tomorrow.getDate() + 2);
    }

    if (tomorrow.getDay() == SUNDAY) {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }

    // No user associated with the button, so set the booking for the user
    var userId = $('.current-user')[0].innerText;

    var eventData = populateEventDefaults(tomorrow, tomorrow, userId);

    createBookingModal(eventData, true);
  });

  $(document).on('click', '.booked-time-status .btn', event => {
    const oldValue = $('#booked_time_booking_status')[0].value;
    const newValue = event.target.value;

    // Undo pressed status on any buttons already clicked
    $('.booked-time-status .btn').removeClass('pressed');

    if (oldValue === newValue) {
      $('#booked_time_booking_status')[0].value = '';
    } else {
      // Set the selected button to pressed
      $(event.target).addClass('pressed');
      $('#booked_time_booking_status')[0].value = newValue;
    }
  });

  $(document).on('click', '.booking-status .btn', event => {
    const oldValue = $('#booking_status')[0].value;
    const newValue = event.target.value;

    // Undo pressed status on any buttons already clicked
    $('.booking-status .btn').removeClass('pressed');

    if (oldValue === newValue) {
      $('#booking_status')[0].value = '';
    } else {
      // Set the selected button to pressed
      $(event.target).addClass('pressed');
      $('#booking_status')[0].value = newValue;
    }
  });

  $(document).on('turbolinks:load', event => {
    if ($('.resource-calendar').length === 0) {
      return;
    }

    if ($('.fc-toolbar').length > 0) {
      $('.fc-toolbar').remove();
      $('.fc-view-container').remove();
    }

    var defaultView = 'listWeek';
    var viewType = defaultView;
    var onlySelf = true;
    var viewOptions = '';
    var toolbar = {
      left: 'today prev,next',
      center: 'title',
      right: ''
    };

    // This width also used to control whether or not the weekend checkbox shows in calendar.scss
    if ($(window).width() > 1470) {
      onlySelf = false;
      defaultView = 'resourceTimelineThirtyDay';
      viewOptions =
        'resourceTimelineTenDay resourceTimelineTwentyDay resourceTimelineFourWeek resourceTimelineThirtyDay resourceTimelineNinetyDay listWeek';
      toolbar['right'] = viewOptions;
    } else if ($(window).width() > 1020) {
      onlySelf = false;
      defaultView = 'resourceTimelineTwentyDay';
      viewOptions =
        'resourceTimelineTenDay resourceTimelineTwentyDay resourceTimelineFourWeek resourceTimelineThirtyDay listWeek';
      toolbar['right'] = viewOptions;
    } else if ($(window).width() >= 768) {
      defaultView = 'resourceTimelineTenDay';
      viewOptions = 'resourceTimelineTenDay resourceTimelineTwentyDay listWeek';
      toolbar['right'] = viewOptions;
      onlySelf = false;
    }

    var pageURL = window.location.search;

    var teamIndex = pageURL.indexOf('team_id=');
    if (teamIndex > 0) {
      var teamId = pageURL.substring(teamIndex + 8).split('&')[0];
      $('.calendar-team select').val(teamId);
    }

    var startDate = calendarDate(pageURL);

    var typeIndex = pageURL.indexOf('type=');
    if (typeIndex > 0) {
      defaultView = pageURL.substring(typeIndex + 5);
    }

    var calendarHolder = $('.resource-calendar')[0];

    // Convert string to boolean
    var weekends = $('#weekends').val() === 'true';

    var resourceCalendar = new Calendar(calendarHolder, {
      plugins: [resourceTimelinePlugin, interactionPlugin, listPlugin],
      initialView: defaultView,
      schedulerLicenseKey: '0345976193-fcs-1701481234',
      initialView: defaultView,
      contentHeight: 'auto',
      resourceAreaHeaderContent: translate('booking.resource_title'),
      resourceAreaWidth: '120px',
      resourceOrder: 'priority,name',
      eventOverlap: true,
      eventStartEditable: true,
      eventResourceEditable: true,
      eventDurationEditable: false,
      headerToolbar: toolbar,
      initialDate: startDate,
      resources: function(fetchInfo, successCallback, failureCallback) {
        getResources(fetchInfo, successCallback, onlySelf);
      },
      events: function(fetchInfo, successCallback, failureCallback) {
        // This only takes effect when the events are being refetched,
        // not when the user changes the view type interactively
        // but it saves work in the backend, so let's do it anyhow
        var withStripes = viewType !== 'listWeek';
        getEvents(fetchInfo, successCallback, onlySelf, withStripes);
      },
      views: viewConfigs(weekends),
      eventClassNames: function(arg) {
        return classNames(arg.event);
      },
      eventContent: function(arg, createElement) {
        return eventTitle(arg);
      },
      eventDidMount: function(arg) {
        hoverText(arg.el, arg.event.title);
        arg.el.id = arg.event.id.split('_')[1];
        if (arg.event.extendedProps.feditable === false) {
          arg.event.setProp('startEditable', false);
          $(arg.el).removeClass('fc-event-draggable');
        }
        if (arg.event.extendedProps.category == 'project') {
          projectStripeColor(arg);
        }
      },
      resourceLabelDidMount: function(arg) {
        hoverText(arg.el, arg.resource.title);
        workingAndVacation(arg.el, arg.resource.extendedProps);
      },
      resourceLabelClassNames: function(arg) {
        return laneClass(arg.resource, true);
      },
      resourceLabelContent: function(arg, createElement) {
        return labelContent(arg.resource, createElement);
      },
      resourceLaneClassNames: function(arg) {
        return laneClass(arg.resource, true);
      },
      eventClick: function(arg) {
        onEventClicked(arg.event, new Date(arg.event.start), true);
      },
      eventDrop: function(info) {
        onEventDrop(info);
      },
      dateClick: function(info) {
        onDateClicked(info);
      },
      datesSet: function(dateInfo) {
        viewType = dateInfo.view.type;
      }
    });

    resourceCalendar.render();
  });
};

export default ResourceCalendar;
