import $ from 'jquery';
import createBookingModal from 'booking_modal';
import { populateExistingEvent } from 'calendar_view_helper';
import populateFormFields from 'populate_form_fields';
import updateEvent from 'update_event';
import { updateForCategory } from 'booking_category_utils';

const getProjectEvents = (fetchInfo, successCallback, withStripes) => {
  $.ajax({
    url: '/project_event_data',
    contentType: 'application/json',
    dataType: 'json',
    data: {
      start: fetchInfo.startStr,
      end: fetchInfo.endStr,
      with_stripes: withStripes,
      id: $('.project-calendar')[0].id
    }
  })
    .done(function(response) {
      successCallback(response);
    })
    .fail(function(response) {
      Turbolinks.visit(response.url);
    });
};

const getEvents = (fetchInfo, successCallback, onlySelf, withStripes) => {
  $.ajax({
    url: '/event_data',
    contentType: 'application/json',
    dataType: 'json',
    data: {
      start: fetchInfo.startStr,
      end: fetchInfo.endStr,
      onlyself: onlySelf,
      with_stripes: withStripes,
      team_id: $('.calendar-team select').val(),
      skill_id: $('.calendar-skill select').val(),
      score: $('.calendar-score select').val()
    }
  })
    .done(function(response) {
      successCallback(response);

      $('.time-form').attr('non-work-dates', response[0].non_work_dates);
    })
    .fail(function(response) {
      Turbolinks.visit(response.url);
    });
};

const classNames = event => {
  var statusColor = '';
  if (event.extendedProps.statusColor) {
    statusColor = event.extendedProps.statusColor.split(':')[0];
  }
  var classes = [event.extendedProps.category, statusColor];
  if (event.extendedProps.feditable) {
    classes.push('feditable');
  }

  return classes;
};

const hoverText = (element, value) => {
  // Set hover text
  element.title = value;
};

const onEventClicked = (event, dateClicked, showWork) => {
  if (event.extendedProps.feditable) {
    const bookingId = event.id.split('_')[1];
    const status = event.extendedProps.status;

    var finish = new Date(event.end);
    // fullCalendar sets the finish as the next day
    finish.setDate(finish.getDate() - 1);
    event['finish'] = finish;

    event.resourceIds = event._def.resourceIds;

    $('.booking-user-name').val(event.title);
    $('.booking-user-id').val(event.extendedProps.userId);

    createBookingModal(event, true, bookingId);
  }
};

const onEventDrop = info => {
  if ($(info.el).hasClass('feditable')) {
    var eventData = populateExistingEvent(info);
    var eventId = populateFormFields(eventData);

    if (eventId < 0) {
      var userName = $('.fc-body .fc-resource-area').find(
        '[data-resource-id=' + event.resourceId + ']'
      )[0].innerText;
      $alert.innerHTML = translate('errors.booking.other_user') + userName;

      event.resourceId = prevEventUserId;
      event.start = prevStart;
      event.end = prevEnd;
      return;
    }

    updateEvent(eventId);

    updateForCategory(eventData['extendedProps']['category']);

    $('#new_booking').submit();
  }
};

const projectStripeColor = arg => {
  // If the events haven't been refetched, they might still have the striping information
  // If we're in a listWeek view, skip this step where the striping is rendered
  if (arg.view.type == 'listWeek') {
    return;
  }

  if (
    arg.event.extendedProps.statusColor.startsWith('fade-blue') ||
    arg.event.extendedProps.statusColor.startsWith('fade-green')
  ) {
    var styling = arg.event.extendedProps.statusColor.split(':')[1];
    // Set the CSS for colour, so it blanks days where no time is booked
    arg.el.style.backgroundImage = styling;
  }
};

export {
  getEvents,
  getProjectEvents,
  hoverText,
  onEventClicked,
  onEventDrop,
  classNames,
  projectStripeColor
};
